header {
  .storefront-menu {
    .dropdown-menu {
      height: auto;
      max-height: 80vh;
      overflow-x: hidden;
    }
  }
  .menu-user {
    .dropdown-toggle {
      padding-top: 19px !important;
      padding-bottom: 19px !important;
    }
  }

  .navbar {
    .megamenu {
      padding: 0;

      .columns {
        gap: 0;
      }

      .megamenu-subheader {
        padding-bottom: 5px;
        padding-left: 0;
        margin: 0.5rem 1.3rem;
        border-bottom: 1px solid var(--border_color);
        color: var(--text);
        font-family: var(--font_family_brand_regular);
      }

      a {
        padding: 10px 30px 10px 20px;
        margin-left: 0;
      }
    }
  }
}
.bg-brand-subheader {
  position: relative;
  top: 58px;

  @include media-breakpoint-up(lg) {
    top: 70px;
  }
  .global-alert {
    height: auto;
  }
}
