// multi-select dropdowns
.p-multiselect,
.p-dropdown {
  .p-multiselect-trigger,
  .p-dropdown-trigger {
    height: auto; // fix for responsive table overflow
  }
}

.p-dropdown-panel {
  min-width: 100%;
  background-color: #fff;
  border: 1px solid var(--border_color);
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
}
