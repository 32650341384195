.weather-alert {
  .card-body {
    height: 350px;
    overflow-y: auto;
  }
  .card-header {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }
  .collapsed {
    height: 6rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .lineclamp {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    white-space: normal;
  }
  .wthrTitle {
    font-size: 14px;
  }
}
.weather-alert.card .card-header a:link {
  font-size: 1rem;
  font-family: 'PrecisionSans_W_Rg', 'Helvetica Neue', Arial, sans-serif;
}
