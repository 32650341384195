.page-home {
  --weather-grid-rows: 3 / 5;
  .welcome {
    min-height: 200px;
    display: block;
    background-image: url(/assets/images/home/placeholder.png);
    background-position: center;
    background-size: cover;

    &-overlay {
      background-color: rgba(255, 255, 255, 0.175);
      border-radius: 4px;
      transition: all 0.2s ease-in-out;
      transform: scale(1);

      &:hover {
        background-color: rgba(255, 255, 255, 0.275);
        transform: scale(1.1);
      }
    }

    &-notification {
      position: relative;
      &::after {
        content: '';
        display: inline-block;
        position: absolute;
        left: 5px;
        width: 10px;
        height: 10px;
        background-color: #fff;
        border-radius: 50%;
      }
    }
  }
  .card .card-body {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
  .card-bordered {
    background-color: transparent;
    border: 1px solid var(--rich400);
    box-shadow: none;
  }

  .boxpoll {
    .divider {
      border-top: 2px solid rgba(202, 31, 132, 1);
    }

    .stretched-link:hover::after {
      box-shadow: 0 0 15px rgba(96, 80, 119, 0.6);
    }
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(auto-fill, 1fr);
    grid-auto-rows: auto;
    gap: 30px;

    @include media-breakpoint-down(lg) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .widget {
    display: grid;
  }

  .weather {
    grid-column: 1 / 3;
    grid-row: var(--weather-grid-rows);
  }
}
