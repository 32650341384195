.terms-conditions {
  &.scrollbox {
    height: 400px;
    padding: 0.5rem;
    border: 1px solid var(--rich300);
    overflow-y: scroll;
  }

  ol {
    padding-left: 0;
    counter-reset: item;

    li {
      display: block;
      padding-left: 34px;
    }

    li::before {
      margin-right: 9px;
      margin-left: -34px;
      content: counters(item, ".") " ";
      counter-increment: item;
    }
  }

  .border-bottom {
    border-bottom-color: #000 !important;
  }
}
