.site-root {
  .site-content {
    margin-top: 4.375rem;
    padding-bottom: 4.375rem; // 70px

    + * {
      padding-top: 0;
    }

    @include media-breakpoint-down(md) {
      margin-top: 3.625rem;
      padding-bottom: 3.625rem; //58px
    }
  }
}

.container-fluid {
  @include media-breakpoint-up(lg) {
    width: 98%;
  }

  @include media-breakpoint-up(xl) {
    width: 94%;
  }
}

.page-header {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;

  h1 {
    margin-top: 0;
    margin-bottom: 0;
  }

  h2 {
    margin-top: 0;
    margin-bottom: 0;
  }

  margin-bottom: 2.5rem;

  &.page-header-drilldown {
    background-color: transparent;
    margin-bottom: 0;
  }
}

.error {
  &-body {
    text-align: center;
  }
}
.break-word {
  word-break: break-all !important;
}
.text-prewrap {
  white-space: pre-line;
}
.outlinenone {
  outline: none;
}
.cursor {
  cursor: pointer;
}
.textUpperCase {
  text-transform: uppercase;
}
.textCapitalize {
  text-transform: capitalize;
}
.text-pink {
  color: var(--hyperlink) !important;
}

.text-blue {
  color: blue !important;
}
.icon-grey {
  color: #d8dae4;
}
i.text-primary {
  color: #5f4f77 !important;
  font-size: 12px !important;
}

.dynamic-tooltip-width .tooltip-inner {
  max-width: 300px;
  word-wrap: break-word;
  text-align: left;
}
#selfScrollSection {
  height: calc(100vh - 100px);
  box-sizing: border-box;
  overflow: auto;
}
.ng-select .ng-select-container .ng-value-container .ng-placeholder {
  opacity: 0.3;
}
// Common tooltip right
[data-title] {
  position: relative;
  cursor: pointer;
}
[data-title]:hover::before {
  content: attr(data-title);
  position: absolute;
  display: inline-block;
  padding: 4px 8px;
  border-radius: 2px;
  background: #000;
  color: #dfdddd;
  white-space: nowrap;
  font-size: small;
  top: -7px;
  left: 26px;
}
[data-title]:hover::after {
  content: '';
  position: absolute;
  display: inline-block;
  color: #fff;
  border: 8px solid transparent;
  border-bottom: 8px solid #000;
  top: 15px;
  left: 10px;
  /* Rotate from top left corner (not default) */
  transform-origin: 0 0;
  transform: rotate(270deg);
}
// popover CSS property on mouse over
.fileClaim.tooltip {
  position: relative;
  display: inline-block;
  opacity: 1;
}

.fileClaim.tooltip .tooltiptext {
  visibility: hidden;
  width: 25em;
  background-color: #fff;
  color: #000;
  text-align: center;
  border-radius: 6px;

  /* Position the tooltip */
  position: absolute;
  top: -115px;
  left: 30px;
  z-index: 1;
}

.fileClaim .tooltip-right::after {
  content: '';
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 15px;
  border-style: solid;
  border-color: transparent #fff transparent transparent;
}

.fileClaim.tooltip:hover .tooltiptext {
  visibility: visible;
}
.newForm {
  color: #0072b8;
  font-weight: bold;
}

// The user-select property in CSS controls how the text in an element is allowed to be selected or not selected
.no-copy {
  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none; /* Firefox all */
  -ms-user-select: none; /* IE 10+ */
  user-select: none; /* Likely future */
}
.all-copy {
  -webkit-user-select: all; /* Chrome all / Safari all */
  -moz-user-select: all; /* Firefox all */
  -ms-user-select: all; /* IE 10+ */
  user-select: all; /* Likely future */
}

#passwordValidation {
  .password-incorrect {
    color: var(--danger);
    font-size: 0.875rem;
  }
}

.word-wrap {
  word-wrap: break-word !important;
}
// make links appear disabled
.disabled {
  pointer-events: none;
  cursor: not-allowed;
  color: var(--disabled) !important;
  i {
    color: var(--disabled) !important;
  }
}
.disabled-btn {
  pointer-events: none;
  cursor: not-allowed;
  color: var(--disabled) !important;
  i {
    color: var(--disabled) !important;
  }
}

.toolbar-right-usps {
  background-color: #e1e1e1;
  border: 1px solid #eee;
  padding: 5px 80px 5px 10px;
}

.dropdown-menu {
  max-height: 300px;
  overflow-y: auto;
  overflow-x: auto;
}
.cycleTimeDaterange.dropdown-menu {
  max-height: 100%;
}

.margin-top-6 {
  margin-top: 6em;
}
.calender-icon-usps {
  color: var(--btn_primary);
}
.list-style-none {
  list-style-type: none;
}
.margin-auto {
  margin: auto;
}
.chart-loading-template-min-height {
  min-height: 200px;
}
.user-list-container {
  padding-bottom: 70px;
}
// for dropup navigation UI
.form-group .dropup .btn.dropdown-toggle {
  width: 100%;
  background-color: var(--white);
  padding-right: 5px;
  background-position: right center !important;
  background-repeat: no-repeat !important;
  text-align: left;
  font-family: var(--font_family_base);
  font-size: var(--font_size_base);
  color: var(--text);
  border: 1px solid var(--border_color);
  border-radius: 2px;
}
.dropup .dropdown-toggle::after {
  float: right;
  margin-top: 0.255em;
}

.sso-logout-header {
  color: var(--h1_color) !important;
}
.sso-logout {
  margin-top: -70px;
}
.sso-logout-txt-color {
  color: #515878 !important;
}
.sso-btn-link {
  color: var(--hyperlink) !important;
  font-family: var(--font-family-sans-serif);
  font-size: 0.875rem;
  margin: -4px 0px 0px 0px;
  padding: 0px;
}
.btn-link.sso-btn-link:not([disabled]):not(.disabled),
 .btn-link.sso-btn-link  {
  border: 0px solid #000 !important;
}
.sso-btn-link:hover {
  background-color: transparent !important;

  text-decoration: none !important;
}
.text-decor-none:hover {
  text-decoration: none !important;
  color: var(--hyperlink);
}
.tint-bg {
  background-color: #f7f6f9 !important;
}
.forecast-tabs .tab-content .tab-pane {
  border-bottom: 0px solid red !important;
}

.forecast-tabs .btn-group .btn-secondary.focus {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
  border: 0px solid transparent !important;
  outline: 0px !important;
}
.font-20 {
  font-size: 20px;
}
.align-center {
  text-align: center;
}
.delete-client-list-icon,
.delete-client-list-icon:hover,
.delete-client-list-icon:active {
  color: #5b637f;
  text-decoration: none !important;
}
.nav-tabs-custom {
  border-top: 1px solid var(--divider_color) !important;
  border-bottom: 0px solid var(--divider_color) !important;
}
.custom-client-divider {
  border-top: 1px solid var(--divider_color) !important;
}
.next-line {
  white-space: pre-wrap;
  resize: vertical;
}
.showhideText {
  color: var(--hyperlink);
  font-size: 14px;
  cursor: pointer;
}

.container {
  border: 1px solid #ccc;
  margin-bottom: 10px;
}

.header {
  background-color: #f2f2f2;
  padding: 10px;
  cursor: pointer;
}

.content {
  padding: 10px;
  display: none;
}

.content.expanded {
  display: block;
}

.scrollContainer {
  height: 8em;
  resize: vertical;
  overflow: auto;
  word-break: break-word;
  white-space: pre-line;
}
.scrollNature {
  display: flex;
  flex-direction: column-reverse;
}
