.page-billing-faq {
  .section-head:hover {
    text-decoration: none;
  }

  .card:first-of-type {
    border-top: none;
  }

  .card {
    .card-header {
      .card-ripple-header {
        padding: 1rem 2rem 1rem 1rem;
      }
    }

    .card-body {
      border-top: 1px solid var(--divider_color);
    }
  }

  .fixedSidenav {
    position: fixed;
    z-index: 1;
    overflow-x: hidden;

    .list-group {
      a {
        color: var(--text);
      }
    }
  }
  .accordion .card-header button[aria-expanded='true'],
  ngb-accordion .card-header button[aria-expanded='true'] {
    color: var(--hyperlink);
  }
  .textUpperCase {
    text-transform: uppercase;
  }
  #expdeliver-Transaction-Detail-Glossary-panel .table-open > tbody > tr > td:first-child,
  #cbr-Transaction-Detail-Glossary-panel .table-open > tbody > tr > td:first-child,
  #cbds-Transaction-Detail-Glossary-panel .table-open > tbody > tr > td:first-child {
    text-transform: uppercase;
  }
}
