.table-unpadded {
  tbody {
    tr {
      td:first-of-type {
        padding-left: 0;
      }

      td:last-of-type {
        padding-right: 0;
      }
    }
  }
}

.table-bank-info {
  width: auto;

  tbody tr td {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.toolbar-daterange {
  flex-wrap: nowrap;

  input {
    &.form-control {
      min-width: 195px;
    }
  }
}
// keep sort icons from breaking to a new line
.p-sortable-column-icon {
  &.pi {
    display: inline;
  }
}

// this css is for forecast table
.forecast-wrapper {
  .table-sm th,
  .table-sm td {
    padding: 4px !important;
  }
  overflow-x: auto;
  padding-bottom: 20px;
  .client-view {
    .p-inputnumber .p-inputtext {
      font-size: 12px;
      padding-left: 4px !important;
    }
    table {
      font-size: 0.75rem;
      letter-spacing: 0.08em;
      border-collapse: separate !important;
      min-width: 100%;
      table-layout: fixed !important;
      border-spacing: 0;
      border-bottom: 0;

      .sticky-header {
        position: sticky;
        background: #fff;
        padding-left: 10px;
        z-index: 3;
        width: 200px;

        &.account {
          left: 0;
        }

        &.facility {
          left: 200px;
          border-bottom: 1px solid #ccc;
        }

        /*  &.volume {
            left: 400px;
            text-transform: uppercase;
          }*/

        &.volume {
          width: 1200px;
          position: unset;
        }
      }

      tbody {
        th,
        td {
          border: 1px solid #ccc;
          border-width: 0 1px 1px 0px;
          /* border-width: 0 0 1px 0px;*/
        }

        th {
          font-weight: normal;

          &.sticky-header.facility {
            vertical-align: middle;

            a[href] {
              display: block;
              text-transform: none;
              padding-top: 4px;
              padding-bottom: 4px;
            }
          }
        }

        td {
          .form-control {
            width: 100%;
            text-align: left;
            background-color: #f7f6f9;

            &[readonly] {
              background-color: #fff;
              border: 0;
            }
          }

          .form-control[disabled] {
            border: 1px solid transparent !important;
            background-color: transparent !important;
          }
          .form-control-error input {
            border: 1px solid #ff0000 !important;
          }
          .form-control-forecast-disabled {
            background-color: #f0f0f0 !important;
          }

          .form-control-forecast-disabled-read-only {
            background-color: transparent !important;
            border: 0px solid #000;
            color: #4a4157 !important;
          }
          .form-control-read-only {
            background-color: #c0c0c0 !important;
            border: 0px solid #000 !important;
            width: 90%;
            text-align: left;
            color: #000 !important;
          }
          .error-msg {
            color: #ff0000 !important;
            display: block;
          }
        }
      }
    }
  }
  .cem-view {
    .p-inputnumber .p-inputtext {
      font-size: 12px;
      height: 24px;
      padding-left: 4px !important;
    }
    table {
      font-size: 0.75rem;
      letter-spacing: 0.08em;
      border-collapse: separate !important;
      min-width: 100%;
      table-layout: fixed !important;
      border-spacing: 0;
      border-bottom: 0;

      .sticky-header {
        position: sticky;
        background: #fff;
        z-index: 3;
        width: 200px;

        &.service {
          left: 0;
        }

        &.account {
          left: 200px;
          border-right: 1px solid #ccc;
          padding-top: 12px;
          background-color: #fff;
          width: 200px;
          z-index: 999;
        }

        &.facility {
          left: 400px;
          width: 200px;
          text-transform: uppercase;
        }

        &.volume {
          width: 1200px;
          position: unset;
        }
      }
      tr.cem-row {
        td,
        th.facility {
          border-bottom: 0px solid red !important;
        }
      }
      tbody {
        th,
        td {
          border: 1px solid #ccc;
          height: 36px !important;
          border-width: 0 0 1px 0px;
        }

        th {
          font-weight: normal;

          &.sticky-header.facility {
            vertical-align: middle;

            a[href] {
              display: block;
              text-transform: none;
              padding-top: 4px;
              padding-bottom: 4px;
            }
          }
        }

        td {
          .form-control {
            width: 100%;
            text-align: left;
            background-color: #f7f6f9;

            &[readonly] {
              background-color: #fff;
              border: 0;
            }
          }

          .form-control[disabled] {
            border: 1px solid transparent !important;
            background-color: transparent !important;
          }
          .form-control-error input {
            border: 1px solid #ff0000 !important;
          }
          .form-control-forecast-disabled {
            background-color: #f0f0f0 !important;
          }

          .form-control-forecast-disabled-read-only {
            background-color: #c0c0c0 !important;
            border: 0px solid #000;
            color: #4a4157 !important;
          }
          .form-control-read-only {
            background-color: #c0c0c0 !important;
            border: 0px solid #000 !important;
            width: 90%;
            text-align: left;
            color: #000 !important;
          }
          .error-msg {
            color: #ff0000 !important;
            display: block;
          }
        }
      }
    }
  }
}
.highlighted-rows,
.accuracy-or-actuals {
  background-color: #fcf4f9 !important;
}

.forecast-wrapper .client-view table tbody > :nth-child(2) .facility,
.forecast-wrapper .client-view table tbody > :nth-child(3) .facility,
.forecast-wrapper .client-view table tbody > :nth-child(4) .facility,
.forecast-wrapper .client-view table tbody > :nth-child(2) .account,
.forecast-wrapper .client-view table tbody > :nth-child(3) .account,
.forecast-wrapper .client-view table tbody > :nth-child(4) .account {
  background-color: #fcf4f9 !important;
}
.forecast-wrapper .client-view table tbody > :nth-child(1) th,
.forecast-wrapper .client-view table tbody > :nth-child(1) td,
.forecast-wrapper .client-view table tbody > :nth-child(1) .facility,
.forecast-wrapper .client-view table tbody > :nth-child(4) th,
.forecast-wrapper .client-view table tbody > :nth-child(4) td,
.forecast-wrapper .client-view table tbody > :nth-child(4) .facility {
  border-bottom: 1px solid #c0b0d6;
}

.forecast-wrapper .client-view table .border-bt-0 {
  border-bottom-width: 0px !important;
}
.forecast-wrapper .client-view table tbody td .form-control-forecast-disabled,
.forecast-wrapper .cem-view table tbody td .form-control-forecast-disabled {
  background-color: #fcf4f9 !important;
}
.forecast-wrapper .client-view table td.week-cols {
  border-left-width: 0px !important;
}
.forecast-wrapper .cem-view table tbody th.no-btm-bdr {
  border-bottom: 3px solid #fff !important;
}
.forecast-wrapper .cem-view .table thead th.no-top-bdr {
  border-top: 0px solid #fff !important;
}
.forecast-wrapper .cem-view table tbody > :nth-child(2),
.forecast-wrapper .cem-view table tbody > :nth-child(2) th,
.forecast-wrapper .cem-view table tbody > :nth-child(3) th,
.forecast-wrapper .cem-view table tbody > :nth-child(3) td,
.forecast-wrapper .cem-view table tbody > :nth-child(4),
.forecast-wrapper .cem-view table tbody > :nth-child(4) th,
.forecast-wrapper .cem-view table tbody > :nth-child(5),
.forecast-wrapper .cem-view table tbody > :nth-child(5) th,
.forecast-wrapper .cem-view table tbody > :nth-child(6),
.forecast-wrapper .cem-view table tbody > :nth-child(6) th,
.forecast-wrapper .cem-view table tbody > :nth-child(3) td .form-control[readonly],
.forecast-wrapper .cem-view table tbody > :nth-child(4) td .form-control[readonly],
.forecast-wrapper .cem-view table tbody > :nth-child(5) td .form-control[readonly],
.forecast-wrapper .cem-view table tbody > :nth-child(6) td .form-control[readonly] {
  background-color: #fcf4f9 !important;
}
.forecast-wrapper .cem-view table th.border-btm-zero {
  border-bottom-width: 0px !important;
}
.forecast-wrapper .client-view table tbody td .form-control-last .p-inputtext,
.forecast-wrapper .cem-view table tbody td .form-control-last .p-inputtext {
  width: 127px !important;
}
.forcast-difference-table {
  width: 70%;
  margin: auto;
}
.forcast-difference-table td {
  text-transform: uppercase;
}

.forecast-wrapper .client-view table tbody td .form-control[readonly] .float-right {
  float: right;
}
.volume-arriving-layer {
  position: sticky;
  left: 650px;
  width: 498px;
  z-index: 9;
}
.pos-relative {
  position: relative;
}
.text-align-right-mr {
  text-align: right;
  margin-right: 10px;
}
.forecast-checkbox-label {
  position: relative;
  top: 2px;
  left: 10px;
}
.font-weight-bold {
  font-weight: bold;
}

.align-parent-store-front {
  padding-top: 30px;
}

.hide {
  position: absolute !important;
  top: -9999px !important;
  left: -9999px !important;
  background-color: red;
}
th.align-from-top-first-child {
  padding-top: 50px;
}
th.align-from-top {
  padding-top: 16px;
}
.nested-table-velocity tr:first-child td {
  border-top: 0px solid red !important;
}
.nested-table-velocity tr:last-child td,
.nested-table-velocity table {
  border-bottom: 0px solid red !important;
}
.align-right {
  text-align: right;
}
.align-center {
  text-align: center;
}
.nested-table-velocity table td {
  height: 67px;
}

.forecast-graph-loading-template {
  min-height: 234px !important;
}

.secondary-header {
  font-family: 'PrecisionSansW-Light', 'Precision Sans W Light', 'Precision Sans W', sans-serif;
  font-weight: 200;
  font-style: normal;
  font-size: 24px;
  margin-top: 10px;
  color: #b70077;
}
.internal-account-details {
  font-style: normal;
  font-size: 15px;
  color: #5f4f77;
}
.select-error-clients div.ng-select-container {
  border: 1px solid red !important;
}
.forecast-details-capitalize {
  text-transform: capitalize;
}
