//TODO: remove when fixed in DS override or customize predefined CSS property

.toolbar-right1 {
  padding-right: 20px;
}

.pbds-chart.pbds-chart-stacked-bar .bar-group .bar[height='0'],
.pbds-chart.pbds-chart-bar .bar[height='0'] {
  height: 0.01px;
}

.pbds-daterange-popover {
  .input-group-append button {
    border: 1px solid #605077 !important;
  }
}

.pr-6rem {
  padding-right: 6rem !important;
}

#payment-activity .p-datatable table .p-datatable table {
  border-bottom: none !important;
}

.mat-radio-button .mat-radio-ripple {
  display: none;
}

// .pbds-daterange-popover,
// .pbds-daterange-popover input.form-control {
//   height: 1.6rem;
// }

.pbds-daterange-popover .input-group-append .btn.btn-secondary {
  padding-top: 4px;
}

.page-case-create .list-case-reasons {
  border-top-width: 0px;
}

.mat-ripple-element {
  height: 10px;
}

.btn-group.border {
  border: 0.05px solid var(--btn_primary) !important;
  z-index: 1;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.toolbar .btn-group {
  height: 1.8rem;
}

.toolbar .btn-group label.btn-secondary,
.toolbar .btn-group label.btn {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

#statusFilter .mat-checkbox {
  display: block;
}

#merchantDataReturns .pbds-chart .axis .tick text,
#networkDataReturns .pbds-chart .axis .tick text {
  font-size: 12.5px;
}

#packageActivityReturnTable table > tbody > tr:hover {
  background: var(--rich200);
}

#deliveredPackages #navigationSection .tab-content .tab-pane {
  padding: 0;
}

#deliveredPackages .metric-block-indicator.increase,
#deliveredPackages .metric-block-indicator.decrease {
  color: #717171;
}

.mat-radio-outer-circle,
.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  left: 0px !important;
}

.mat-radio-label-content {
  padding-left: 10px !important;
}

.pb-icon-dochub {
  height: 20px !important;
  width: 20px !important;
}

.pb-icon-new-file {
  font-size: 9px;
  position: initial;
  left: 2px;
  color: #b70077;
}

.fontMedium {
  font-size: 0.9rem;
}

.tab-content .tab-pane {
  border-bottom: 0;
}

.alert-danger,
#toast-container .toast-error {
  background-image: url('data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjI2IiB2aWV3Qm94PSIwIDAgMjYgMjYiIHdpZHRoPSIyNiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIHRyYW5zZm9ybT0ibWF0cml4KC0xIDAgMCAtMSAyNSAyNSkiPjxjaXJjbGUgY3g9IjEyIiBjeT0iMTIiIHI9IjEyIiBzdHJva2U9IiNERTBGMEUiIHN0cm9rZS13aWR0aD0iMiIvPjxnIHRyYW5zZm9ybT0idHJhbnNsYXRlKDExIDUpIj48Y2lyY2xlIGN4PSIxIiBjeT0iMSIgZmlsbD0iI0RFMEYwRSIgcj0iMSIvPjxwYXRoIGQ9Im0xIDV2OCIgc3Ryb2tlPSIjREUwRjBFIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS13aWR0aD0iMiIvPjwvZz48L2c+PC9zdmc+') !important;
}

app-no-file-available-modal .p-datatable-wrapper table.p-datatable-table {
  border-bottom: none;
}

#caseDetailComment .card-filled-header .card-header {
  background-color: var(--rich100);
}

#cbdscycleTime .card-header + .card-body {
  padding-bottom: 50px;
}

.p-multiselect {
  background-color: transparent;
  border: 0px solid var(--border_color);
  height: 30px;
  //max-width: 15rem;
  min-width: 12rem;
  width: 12rem;
  max-width: 12rem;
}

.p-multiselect .p-multiselect-label {
  padding: 0.2rem 5px 0.2rem 0.25rem;
}

.dashboard .toolbar .form-group .report-segment.dropdown .btn.dropdown-toggle {
  background-image: none !important;
}

.report-segment button.dropdown-toggle.dropdown-toggle::after {
  display: none !important;
}

.pbi-icon-mini.pbi-small-triangle-down::before {
  font-size: larger;
}

.p-multiselect .p-multiselect-trigger,
.p-multiselect .p-dropdown-trigger,
.p-dropdown .p-multiselect-trigger,
.p-dropdown .p-dropdown-trigger {
  padding-right: 5px;
}

.dashboard .toolbar .form-group .dropdown .dropdown-menu {
  min-width: 16.3rem;
}

.p-datatable th,
.p-datatable td,
.p-treetable th,
.p-treetable td {
  padding: 0.35rem;
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

#caseFilter label {
  background: var(--rich200);
  font-size: 0.85rem;

  border: 1px solid transparent; /* keeps layout from jumping */
}
#caseFilter input {
  position: absolute;
  opacity: 0;
}
#caseFilter input[type='radio']:checked + label {
  background: var(--rich500);
  color: white;
}
#caseFilter input[type='radio']:checked + label.second {
  border-radius: 0px 4px 4px 0px;
}
#caseFilter input[type='radio']:checked + label.first {
  border-radius: 4px 0px 0px 4px;
}
#caseFilter .btn-group.border {
  border: 1px solid var(--btn_primary) !important;
}
