.page-case-create {
  margin-bottom: 0;

  .services {
    .service {
      border: 1px solid var(--text);
      position: relative;
      cursor: pointer;
      background-color: #fff;

      &.active {
        background-color: var(--rich200);

        &::after {
          content: '';
          width: 17px;
          height: 13px;
          background-repeat: no-repeat;
          background-image: url('/assets/images/commonIcon/tip.svg');
          position: absolute;
          left: calc(50% - 6px);
          bottom: -12px;
        }
      }

      img {
        filter: invert(31%) sepia(8%) saturate(2040%) hue-rotate(223deg) brightness(100%) contrast(87%);
      }
    }
  }

  .list-case-types {
    min-width: 250px;

    li {
      display: block;
      margin-bottom: 0;

      .case-type {
        border-top: none;
        border-right: 4px solid transparent;
        border-bottom: none;
        border-left: none;
        border-radius: 0px;
        display: block;
        padding: 0.75rem;
        color: var(--text);
        text-decoration: none;
        margin-top: 0;
        margin-bottom: 0;
        background-color: var(--rich100);

        &:hover {
          background-color: var(--rich200);
          cursor: pointer;
        }

        &.active {
          border-right-color: var(--text);
          background-color: var(--rich200);
          outline: none;
        }

        &:focus {
          outline-width: 1px;
          outline-color: var(--text);
          outline-offset: -1px;
          border-radius: 0px;
          outline: none;
        }

        i[class^='pbi-icon'] {
          font-size: 1.25rem;
          vertical-align: sub;
          padding-right: 0.5rem;
        }
      }
    }
  }

  .list-case-reasons {
    margin-bottom: 0;
    border-left: 1px solid var(--divider_color);
    min-height: 250px;

    @include media-breakpoint-down(xs) {
      border-left: none;
      border-top: 1px solid var(--divider_color);
    }

    li {
      display: block;
      margin-bottom: 0.5rem;

      .case-reason {
        display: block;
        border: 1px solid var(--text);
        padding: 0.5rem;
        background-color: var(--white);
        color: var(--text);
        text-decoration: none;
        margin-bottom: 0;
        border-radius: 2px;

        &:hover {
          background-color: var(--rich200);
          cursor: pointer;
        }

        &.active {
          color: #fff;
          background-color: var(--text);
          cursor: default;
        }

        &:focus {
          outline-width: 1px;
          outline-color: var(--text);
          outline-offset: 0px;
          border-radius: 0px;
        }
      }
    }
  }

  .case-reason-label::before {
    content: '';
    display: block;
    height: 100px;
    margin-top: -70px;
  }

  .one-container-tag {
    margin-top: 3px !important;
    font-size: 0.8rem !important;
  }

  .optional-value {
    font-size: 10px;
    font-style: italic;
    padding-right: 5px;
  }

  .brand-font-light {
    font-family: 'PrecisionSans_W_Lt', 'Helvetica Neue', Arial, sans-serif;
  }

  .faq-content {
    top: 100px;
  }
}
.trackingDelivered div.card {
  border-width: 0px!important;
}

.trackingDelivered .accordion .card-header button[aria-expanded=true]::before, .trackingDelivered .accordion .card-header .btn.btn-link[aria-expanded=true]::before, .trackingDelivered .accordion-group .card-header button[aria-expanded=true]::before,.trackingDelivered  .accordion-group .card-header .btn.btn-link[aria-expanded=true]::before, .trackingDelivered  ngb-accordion .card-header button[aria-expanded=true]::before, .trackingDelivered ngb-accordion .card-header .btn.btn-link[aria-expanded=true]::before {
  transform: rotate(90deg) !important;
}
.trackingDelivered .accordion .card-header button::before, .trackingDelivered .accordion .card-header .btn.btn-link::before,.trackingDelivered  .accordion-group .card-header button::before, .trackingDelivered .accordion-group .card-header .btn.btn-link::before, .trackingDelivered ngb-accordion .card-header button::before, .trackingDelivered ngb-accordion .card-header .btn.btn-link::before {
  display: inline-block !important;;
  margin-top: 0 !important;;
  margin-right: 0.3rem !important;;
  content: "" !important;;
  font-family: "pbi-icon-outline" !important;;
  float: left !important;;
  transform: rotate(0deg) !important;;
  transition: transform 200ms !important;;
}


.trackingDelivered .accordion .card-header button::after, .trackingDelivered .accordion .card-header .btn.btn-link::after,.trackingDelivered .accordion-group .card-header button::after,.trackingDelivered .accordion-group .card-header .btn.btn-link::after,.trackingDelivered ngb-accordion .card-header button::after,.trackingDelivered ngb-accordion .card-header .btn.btn-link::after {
  display: none !important;
}

.trackingDelivered .accordion .card-header button::after,.trackingDelivered .accordion .card-header .btn.btn-link::after, .trackingDelivered .accordion-group .card-header button::after,.trackingDelivered .accordion-group .card-header .btn.btn-link::after, .trackingDelivered ngb-accordion .card-header button::after, .trackingDelivered ngb-accordion .card-header .btn.btn-link::after {
  display: none !important;
}
.trackingDelivered .tracking-faq {
  text-align: justify;
  padding-left:20px;
}
