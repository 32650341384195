.shepherd-element {
  max-width: 350px;

  .shepherd-content {
    .shepherd-text {
      text-align: left;
      padding: 1.5rem;
      color: var(--text);
    }
  }

  .shepherd-footer {
    position: unset;
    justify-content: left;
    padding: 0;
    border-top: 1px solid var(--gray200);
    background-color: var(--gray100);
    font-size: 1rem;
  }
}
