.page-case-details {
  #case-detail {
    #caseDetailsLeftSection {
      .textCollapsed {
        max-height: 5rem;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .lineclamp {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
      .description {
        display: -webkit-box;
        -webkit-line-clamp: 8;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
      .comment-avatar {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid var(--text);
        color: var(--text);
        width: 32px;
        height: 32px;
        border-radius: 50%;
        font-size: 1.09em;
        text-transform: uppercase;
        padding: 2px 0 0 0;
      }
      div.border-last:last-child {
        border-bottom: 1px solid silver;
      }
      .small + p.word-break {
        margin-top: -1.3rem;
        word-break: break-all;
      }
      .comment-box {
        &-with-attachment {
          transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
          transition-property: border-color, box-shadow !important;
          border: 1px solid var(--divider_color);
          background-color: var(--rich100);

          &.active {
            box-shadow: 1px 1px 2px 3px var(--rich200);
          }

          textarea {
            border: 0;
            resize: none;
            min-height: 38px;
            height: 38px;
            transition: height 0.3s;

            &:focus {
              border: 0 !important;
            }

            &.highlight {
              height: 84px;
              resize: vertical;

              &-no-anim {
                transition: height 0s;
              }
            }
          }

          .mat-checkbox-layout {
            margin-bottom: 0;
          }
        }
      }
      #caseDetailDescription {
        .badge {
          letter-spacing: 0.2px;
          cursor: not-allowed;
        }
      }
      #commentBoxWithAttachment,
      #pbInternalcommentBox {
        ::-webkit-input-placeholder {
          /* Chrome/Opera/Safari */
          color: var(--text);
          opacity: 0.3;
          font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
          font-size: 14px;
          font-weight: normal;
        }
        ::-moz-placeholder {
          /* Firefox 19+ */
          color: var(--text);
          opacity: 0.3;
          font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
          font-size: 14px;
          font-weight: normal;
        }
        :-ms-input-placeholder {
          /* IE 10+ */
          color: var(--text);
          opacity: 0.3;
          font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
          font-size: 14px;
          font-weight: normal;
        }
        :-moz-placeholder {
          /* Firefox 18- */
          color: var(--text);
          opacity: 0.3;
          font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
          font-size: 14px;
          font-weight: normal;
        }
      }
    }
    #caseDetailsRightSection {
      .table-unstyled > thead > tr > td:first-child {
        padding-left: 0;
      }
      .table-unstyled > thead > tr > td:nth-child(2) > a {
        word-break: break-all;
      }
      .col-5 {
        max-width: 46.66667%;
      }
      #caseDetailAttachment table {
        table-layout: auto;
      }
      #caseDetailAttachment table.rowCollapsed {
        max-height: 9rem;
        overflow: hidden;
      }
      #case-detail-sidebar.table {
        table-layout: fixed;
        width: 100%;
      }

      .word-break {
        word-break: break-all;
      }
      .attachmentListCollapsed {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
      .inline-flex {
        display: inline-flex;
      }
      .trasparent {
        background-color: transparent;
      }
    }
  }
  #textareaRef:focus + button {
    display: none;
  }
}
