.fulfill-section {
  margin-top: 2rem;
  border-top: 1px solid var(--divider_color);
}

.add-ref-col {
  flex: auto;
  max-width: 54.5%;
  padding-right: 16px;
  padding-left: 16px;
}
@media (max-width: 992px) {
  .add-ref-col {
    max-width: 100%;
  }
}

.item-col {
  flex: auto;
  max-width: 58.5%;
  padding-right: 16px;
  padding-left: 16px;
}
@media (max-width: 992px) {
  .item-col {
    flex: none;
    max-width: 100%;
  }
}

.item-table {
  max-width: 100%;
}

.create-receive-items .fulfill-section {
  margin-top: 2rem;
  border-top: 1px solid var(--divider_color);
}
.create-receive-items .input-icons i {
  position: absolute;
}
.create-receive-items .search-icon-color {
  color: #b3b6c2;
}
.create-receive-items .icon {
  padding: 12px;
  min-width: 40px;
}
.create-receive-items .input-field {
  width: 100%;
  padding-left: 40px;
  text-align: left;
}

.item-picklist .p-picklist-item {
  display: block;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  padding: 4px 0px;
}
.item-picklist ul li {
  margin-bottom: 0rem;
  margin-left: 16px;
  margin-right: 16px;
}
.item-picklist .p-picklist .p-picklist-list .p-picklist-item {
  border-bottom: 1px solid var(--divider_color);
}
.item-picklist .p-picklist .p-picklist-filter-container .p-picklist-filter-input {
  padding-left: 2rem;
}
.item-picklist .p-picklist-filter-input {
  width: 100%;
  height: 40px;
  background: var(--form_control_bg_richcolor);
  border: 1px solid var(--border_color);
  color: var(--text);
  display: block;
  font-size: var(--font_size_base);
  font-weight: 400;
}
.item-picklist .p-picklist .p-picklist-filter-container {
  padding: 1rem;
  background: #ffffff;
  border: 1px solid var(--divider_color);
  border-bottom: 0 none;
}
.item-picklist .p-picklist .p-picklist-filter-container .p-picklist-filter-icon {
  left: 0.6rem;
  color: var(--text);
}
.item-picklist .p-picklist-filter-icon {
  position: absolute;
  top: 50%;
  margin-top: -0.5rem;
}
.item-picklist .p-picklist .p-picklist-list {
  border: 1px solid var(--divider_color);
  background: #ffffff;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}
.item-picklist .p-picklist .p-picklist-header {
  background: var(--rich100);
  border: 1px solid var(--divider_color);
  padding: 0.5rem;
  border-bottom: 0 none;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}
.item-picklist .p-picklist-title {
  font-size: var(--font_size_lg);
  font-family: var(--font_family_brand_regular);
  margin-bottom: 0;
}
.item-picklist .p-picklist-buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.item-picklist .p-button {
  -webkit-appearance: none;
  align-items: center;
  background-color: var(--btn_secondary_bg);
  background-image: none;
  border-radius: var(--border_radius);
  border: 1pxsolidvar --btn_primary;
  color: var(--btn_primary);
  cursor: pointer;
  display: inline-flex;
  font-size: var(--font_size_base);
  font-family: var(--font_family_brand_regular);
  line-height: var(--line_height_base);
  margin-right: 0.1em;
  overflow: visible;
  padding: 0.5rem 0.75rem;
  position: relative;
  text-align: center;
  text-decoration: none !important;
  -webkit-user-select: none;
  user-select: none;
  vertical-align: bottom;
  zoom: 1;
}
.item-picklist .p-picklist button[icon*=pi-angle-double-right] {
  display: none;
}
.item-picklist .p-picklist button[icon*=pi-angle-double-left] {
  display: flex;
}
.item-picklist .product-item {
  display: flex;
  align-items: center;
  padding: 0.5rem;
  width: 100%;
  color: var(--text);
  font-family: var(--font-family-sans-serif);
  font-size: 0.875rem;
}
.item-picklist .product-item img {
  width: 40px;
  margin-right: 1rem;
}
.item-picklist .product-item .product-list-detail {
  flex: 1 1 0;
}
.item-picklist .product-item .product-category-icon {
  vertical-align: middle;
  margin-right: 0.5rem;
}
.item-picklist .product-item .product-category {
  vertical-align: middle;
  line-height: 1;
}
.item-picklist .product-item .receive-border {
  border-bottom: solid var(--divider_color) 1px;
}

.fulfill-section {
  margin-top: 2rem;
  border-top: 1px solid var(--divider_color);
}

.input-icons i {
  position: absolute;
}

.search-icon-color {
  color: #b3b6c2;
}

.icon {
  padding: 12px;
  min-width: 40px;
}

.input-field {
  width: 100%;
  padding-left: 40px;
  text-align: left;
}

.list-group-item {
  border-top: 0px;
  border-bottom: 0px;
}

.filter-width {
  max-width: 250px;
}

.pull-right {
  float: right;
}

.searchbarWidth {
  min-width: 190px;
}

.list-group.list-group-flush.divider {
  border: 1px;
}