.item-picklist {
  .p-picklist-item {
    display: block;
    cursor: pointer;
    overflow: hidden;
    position: relative;
    padding: 4px 0px;
  }

  ul li {
    margin-bottom: 0rem;
    margin-left: 16px;
    margin-right: 16px;
  }
  

  .p-picklist .p-picklist-list .p-picklist-item {
    border-bottom: 1px solid var(--divider_color);
  }

  .p-picklist .p-picklist-filter-container .p-picklist-filter-input {
    padding-left: 2rem;
  }

  .p-picklist-filter-input {
    width: 100%;
    height: 40px;
    background: var(--form_control_bg_richcolor);
    border: 1px solid var(--border_color);
    color: var(--text);
    display: block;
    font-size: var(--font_size_base);
    font-weight: 400;
  }

  .p-picklist .p-picklist-filter-container {
    padding: 1rem;
    background: #ffffff;
    border: 1px solid var(--divider_color);
    border-bottom: 0 none;
  }

  .p-picklist .p-picklist-filter-container .p-picklist-filter-icon {
    left: .6rem;
    color: var(--text);
    
  }

  .p-picklist-filter-icon {
    position: absolute;
    top: 50%;
    margin-top: -0.5rem;
  }

  .p-picklist .p-picklist-list {
    border: 1px solid var(--divider_color);
    background: #ffffff;
    

    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
  }

  .p-picklist .p-picklist-header {
    background: var(--rich100);
    
    border: 1px solid var(--divider_color);
    padding: .5rem;
    border-bottom: 0 none;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
  }

  .p-picklist-title {
    font-size: var(--font_size_lg);
    font-family: var(--font_family_brand_regular);
    margin-bottom: 0;
  }

  .p-picklist-buttons {
	display: flex;
	flex-direction: column;
	justify-content: center;
    }

  .p-button {
	-webkit-appearance: none;
	align-items: center;
	background-color: var(--btn_secondary_bg);
	background-image: none;
	border-radius: var(--border_radius);
	border: 1pxsolidvar(--btn_primary);
	color: var(--btn_primary);
	cursor: pointer; 
	display: inline-flex;
	font-size: var(--font_size_base);
	font-family: var(--font_family_brand_regular);
	line-height: var(--line_height_base);
	margin-right: .1em;
	overflow: visible;
	padding: .5rem .75rem;
	position: relative;
	text-align: center;
	text-decoration: none !important;
	-webkit-user-select: none;
	user-select: none;
	vertical-align: bottom;
	zoom: 1;
  }

  .p-picklist button[icon*=pi-angle-double-right] {
	display: none;}

	.p-picklist button[icon*=pi-angle-double-left] {
		display: flex;}

  .product-item {
    display: flex;
    align-items: center;
    padding: 0.5rem;
    width: 100%;
    color: var(--text);
    font-family: var(--font-family-sans-serif);
    font-size: 0.875rem;
    img {
      width: 40px;

      margin-right: 1rem;
    }

    .product-list-detail {
      flex: 1 1 0;
    }

    .product-list-action {
      // display: flex;
      // flex-direction: column;
      // align-items: flex-end;
    }

    .product-category-icon {
      vertical-align: middle;
      margin-right: 0.5rem;
    }

    .product-category {
      vertical-align: middle;
      line-height: 1;
    }
    .receive-border {
      border-bottom: solid var(--divider_color) 1px;
    }
  }
}
