// section border //@at-root

.fulfill-section {
  margin-top: 2rem;
  border-top: 1px solid var(--divider_color);
}

.add-ref-col {
  flex: auto;
  max-width: 54.5%;
  @media (max-width: 992px) {
    max-width: 100%;
  }
  padding-right: 16px;
  padding-left: 16px;
}

.item-col {
  flex: auto;
  max-width: 58.5%;
  @media (max-width: 992px) {
    flex: none;
    max-width: 100%;
  }
  padding-right: 16px;
  padding-left: 16px;
}

.item-table {
  max-width: 100%;
}
