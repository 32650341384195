@charset "UTF-8";
.bg-rich-100 {
  background-color: var(--rich100) !important;
}

.site-root .site-content {
  margin-top: 4.375rem;
  padding-bottom: 4.375rem;
}
.site-root .site-content + * {
  padding-top: 0;
}
@media (max-width: 767.98px) {
  .site-root .site-content {
    margin-top: 3.625rem;
    padding-bottom: 3.625rem;
  }
}

@media (min-width: 992px) {
  .container-fluid {
    width: 98%;
  }
}
@media (min-width: 1200px) {
  .container-fluid {
    width: 94%;
  }
}

.page-header {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  margin-bottom: 2.5rem;
}
.page-header h1 {
  margin-top: 0;
  margin-bottom: 0;
}
.page-header h2 {
  margin-top: 0;
  margin-bottom: 0;
}
.page-header.page-header-drilldown {
  background-color: transparent;
  margin-bottom: 0;
}

.error-body {
  text-align: center;
}

.break-word {
  word-break: break-all !important;
}

.text-prewrap {
  white-space: pre-line;
}

.outlinenone {
  outline: none;
}

.cursor {
  cursor: pointer;
}

.textUpperCase {
  text-transform: uppercase;
}

.textCapitalize {
  text-transform: capitalize;
}

.text-pink {
  color: var(--hyperlink) !important;
}

.text-blue {
  color: blue !important;
}

.icon-grey {
  color: #d8dae4;
}

i.text-primary {
  color: #5f4f77 !important;
  font-size: 12px !important;
}

.dynamic-tooltip-width .tooltip-inner {
  max-width: 300px;
  word-wrap: break-word;
  text-align: left;
}

#selfScrollSection {
  height: calc(100vh - 100px);
  box-sizing: border-box;
  overflow: auto;
}

.ng-select .ng-select-container .ng-value-container .ng-placeholder {
  opacity: 0.3;
}

[data-title] {
  position: relative;
  cursor: pointer;
}

[data-title]:hover::before {
  content: attr(data-title);
  position: absolute;
  display: inline-block;
  padding: 4px 8px;
  border-radius: 2px;
  background: #000;
  color: #dfdddd;
  white-space: nowrap;
  font-size: small;
  top: -7px;
  left: 26px;
}

[data-title]:hover::after {
  content: "";
  position: absolute;
  display: inline-block;
  color: #fff;
  border: 8px solid transparent;
  border-bottom: 8px solid #000;
  top: 15px;
  left: 10px;
  /* Rotate from top left corner (not default) */
  transform-origin: 0 0;
  transform: rotate(270deg);
}

.fileClaim.tooltip {
  position: relative;
  display: inline-block;
  opacity: 1;
}

.fileClaim.tooltip .tooltiptext {
  visibility: hidden;
  width: 25em;
  background-color: #fff;
  color: #000;
  text-align: center;
  border-radius: 6px;
  /* Position the tooltip */
  position: absolute;
  top: -115px;
  left: 30px;
  z-index: 1;
}

.fileClaim .tooltip-right::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 15px;
  border-style: solid;
  border-color: transparent #fff transparent transparent;
}

.fileClaim.tooltip:hover .tooltiptext {
  visibility: visible;
}

.newForm {
  color: #0072b8;
  font-weight: bold;
}

.no-copy {
  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none; /* Firefox all */
  -ms-user-select: none; /* IE 10+ */
  user-select: none; /* Likely future */
}

.all-copy {
  -webkit-user-select: all; /* Chrome all / Safari all */
  -moz-user-select: all; /* Firefox all */
  -ms-user-select: all; /* IE 10+ */
  user-select: all; /* Likely future */
}

#passwordValidation .password-incorrect {
  color: var(--danger);
  font-size: 0.875rem;
}

.word-wrap {
  word-wrap: break-word !important;
}

.disabled {
  pointer-events: none;
  cursor: not-allowed;
  color: var(--disabled) !important;
}
.disabled i {
  color: var(--disabled) !important;
}

.disabled-btn {
  pointer-events: none;
  cursor: not-allowed;
  color: var(--disabled) !important;
}
.disabled-btn i {
  color: var(--disabled) !important;
}

.toolbar-right-usps {
  background-color: #e1e1e1;
  border: 1px solid #eee;
  padding: 5px 80px 5px 10px;
}

.dropdown-menu {
  max-height: 300px;
  overflow-y: auto;
  overflow-x: auto;
}

.cycleTimeDaterange.dropdown-menu {
  max-height: 100%;
}

.margin-top-6 {
  margin-top: 6em;
}

.calender-icon-usps {
  color: var(--btn_primary);
}

.list-style-none {
  list-style-type: none;
}

.margin-auto {
  margin: auto;
}

.chart-loading-template-min-height {
  min-height: 200px;
}

.user-list-container {
  padding-bottom: 70px;
}

.form-group .dropup .btn.dropdown-toggle {
  width: 100%;
  background-color: var(--white);
  padding-right: 5px;
  background-position: right center !important;
  background-repeat: no-repeat !important;
  text-align: left;
  font-family: var(--font_family_base);
  font-size: var(--font_size_base);
  color: var(--text);
  border: 1px solid var(--border_color);
  border-radius: 2px;
}

.dropup .dropdown-toggle::after {
  float: right;
  margin-top: 0.255em;
}

.sso-logout-header {
  color: var(--h1_color) !important;
}

.sso-logout {
  margin-top: -70px;
}

.sso-logout-txt-color {
  color: #515878 !important;
}

.sso-btn-link {
  color: var(--hyperlink) !important;
  font-family: var(--font-family-sans-serif);
  font-size: 0.875rem;
  margin: -4px 0px 0px 0px;
  padding: 0px;
}

.btn-link.sso-btn-link:not([disabled]):not(.disabled),
 .btn-link.sso-btn-link  {
  border: 0px solid #000 !important;
}

.sso-btn-link:hover {
  background-color: transparent !important;
  text-decoration: none !important;
}

.text-decor-none:hover {
  text-decoration: none !important;
  color: var(--hyperlink);
}

.tint-bg {
  background-color: #f7f6f9 !important;
}

.forecast-tabs .tab-content .tab-pane {
  border-bottom: 0px solid red !important;
}

.forecast-tabs .btn-group .btn-secondary.focus {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
  border: 0px solid transparent !important;
  outline: 0px !important;
}

.font-20 {
  font-size: 20px;
}

.align-center {
  text-align: center;
}

.delete-client-list-icon,
.delete-client-list-icon:hover,
.delete-client-list-icon:active {
  color: #5b637f;
  text-decoration: none !important;
}

.nav-tabs-custom {
  border-top: 1px solid var(--divider_color) !important;
  border-bottom: 0px solid var(--divider_color) !important;
}

.custom-client-divider {
  border-top: 1px solid var(--divider_color) !important;
}

.next-line {
  white-space: pre-wrap;
  resize: vertical;
}

.showhideText {
  color: var(--hyperlink);
  font-size: 14px;
  cursor: pointer;
}

.container {
  border: 1px solid #ccc;
  margin-bottom: 10px;
}

.header {
  background-color: #f2f2f2;
  padding: 10px;
  cursor: pointer;
}

.content {
  padding: 10px;
  display: none;
}

.content.expanded {
  display: block;
}

.scrollContainer {
  height: 8em;
  resize: vertical;
  overflow: auto;
  word-break: break-word;
  white-space: pre-line;
}

.scrollNature {
  display: flex;
  flex-direction: column-reverse;
}

header .storefront-menu .dropdown-menu {
  height: auto;
  max-height: 80vh;
  overflow-x: hidden;
}
header .menu-user .dropdown-toggle {
  padding-top: 19px !important;
  padding-bottom: 19px !important;
}
header .navbar .megamenu {
  padding: 0;
}
header .navbar .megamenu .columns {
  gap: 0;
}
header .navbar .megamenu .megamenu-subheader {
  padding-bottom: 5px;
  padding-left: 0;
  margin: 0.5rem 1.3rem;
  border-bottom: 1px solid var(--border_color);
  color: var(--text);
  font-family: var(--font_family_brand_regular);
}
header .navbar .megamenu a {
  padding: 10px 30px 10px 20px;
  margin-left: 0;
}

.bg-brand-subheader {
  position: relative;
  top: 58px;
}
@media (min-width: 992px) {
  .bg-brand-subheader {
    top: 70px;
  }
}
.bg-brand-subheader .global-alert {
  height: auto;
}

.p-multiselect .p-multiselect-trigger,
.p-multiselect .p-dropdown-trigger,
.p-dropdown .p-multiselect-trigger,
.p-dropdown .p-dropdown-trigger {
  height: auto;
}

.p-dropdown-panel {
  min-width: 100%;
  background-color: #fff;
  border: 1px solid var(--border_color);
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
}

#edr .min-width {
  min-width: 200px;
}

#rvpCalendar .ngb-dp-navigation-select {
  display: flex;
  flex: 1 1 11rem !important;
}

.pbds-chart.pbds-chart-bar-grouped .bar-group-prediction .prediction-divider {
  stroke: var(--gray300);
  stroke-width: 1;
  stroke-dasharray: 10 10;
}
.pbds-chart.pbds-chart-bar-grouped .bar-group-prediction .bar {
  cursor: pointer;
}
.pbds-chart.pbds-chart-bar-grouped .bar-group-prediction .min-line.inactive {
  opacity: 0.1;
}
.pbds-chart.pbds-chart-bar-grouped .bar-group-prediction .max-line.inactive {
  opacity: 0.1;
}
.pbds-chart.pbds-chart-bar-grouped .bar-group-prediction .range-line.inactive {
  opacity: 0.1;
}

.returns-cycle-section-header {
  padding-bottom: 5px;
  padding-left: 0;
  margin: 0.5rem 1rem;
  border-bottom: 1px solid var(--border_color);
  color: var(--text);
  font-family: "PrecisionSans_W_Rg", "Helvetica Neue", Arial, sans-serif;
  text-transform: uppercase;
  font-size: 0.75rem;
}

.p-datatable th.edrExpand,
.p-datatable td.edrExpand {
  padding: 0.4rem !important;
  border-top-width: 0.4px !important;
}

.p-datatable th.edrExpand {
  border-bottom-width: 0.4px !important;
  border-top-width: 0.4px !important;
}

.p-datatable table {
  table-layout: auto !important;
}

.p-multiselect {
  min-width: 15rem;
}

.multiselect-custom-virtual-scroll .p-multiselect {
  min-width: 20rem;
}

.multiselect-custom .p-multiselect-label {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.multiselect-custom .p-multiselect-label-empty {
  visibility: visible;
}
.multiselect-custom .status-item-value {
  border-radius: 3px;
  display: flex !important;
  margin-right: 0.5rem;
  background-color: var(--primary-color);
  color: var(--primary-color-text);
}
.multiselect-custom .status-item-value img.flag {
  width: 20px;
}
.multiselect-custom .p-multiselect-item {
  display: flex !important;
}

.pbds-chart.pbds-chart-choropleth-map .map .feature {
  fill: var(--rich300);
}

.weather-alert .card-body {
  height: 350px;
  overflow-y: auto;
}
.weather-alert .card-header {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.weather-alert .collapsed {
  height: 6rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.weather-alert .lineclamp {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: normal;
}
.weather-alert .wthrTitle {
  font-size: 14px;
}

.weather-alert.card .card-header a:link {
  font-size: 1rem;
  font-family: "PrecisionSans_W_Rg", "Helvetica Neue", Arial, sans-serif;
}

.terms-conditions.scrollbox {
  height: 400px;
  padding: 0.5rem;
  border: 1px solid var(--rich300);
  overflow-y: scroll;
}
.terms-conditions ol {
  padding-left: 0;
  counter-reset: item;
}
.terms-conditions ol li {
  display: block;
  padding-left: 34px;
}
.terms-conditions ol li::before {
  margin-right: 9px;
  margin-left: -34px;
  content: counters(item, ".") " ";
  counter-increment: item;
}
.terms-conditions .border-bottom {
  border-bottom-color: #000 !important;
}

.table-unpadded tbody tr td:first-of-type {
  padding-left: 0;
}
.table-unpadded tbody tr td:last-of-type {
  padding-right: 0;
}

.table-bank-info {
  width: auto;
}
.table-bank-info tbody tr td {
  padding-top: 0;
  padding-bottom: 0;
}

.toolbar-daterange {
  flex-wrap: nowrap;
}
.toolbar-daterange input.form-control {
  min-width: 195px;
}

.p-sortable-column-icon.pi {
  display: inline;
}

.forecast-wrapper {
  overflow-x: auto;
  padding-bottom: 20px;
}
.forecast-wrapper .table-sm th,
.forecast-wrapper .table-sm td {
  padding: 4px !important;
}
.forecast-wrapper .client-view .p-inputnumber .p-inputtext {
  font-size: 12px;
  padding-left: 4px !important;
}
.forecast-wrapper .client-view table {
  font-size: 0.75rem;
  letter-spacing: 0.08em;
  border-collapse: separate !important;
  min-width: 100%;
  table-layout: fixed !important;
  border-spacing: 0;
  border-bottom: 0;
}
.forecast-wrapper .client-view table .sticky-header {
  position: sticky;
  background: #fff;
  padding-left: 10px;
  z-index: 3;
  width: 200px;
  /*  &.volume {
      left: 400px;
      text-transform: uppercase;
    }*/
}
.forecast-wrapper .client-view table .sticky-header.account {
  left: 0;
}
.forecast-wrapper .client-view table .sticky-header.facility {
  left: 200px;
  border-bottom: 1px solid #ccc;
}
.forecast-wrapper .client-view table .sticky-header.volume {
  width: 1200px;
  position: unset;
}
.forecast-wrapper .client-view table tbody th,
.forecast-wrapper .client-view table tbody td {
  border: 1px solid #ccc;
  border-width: 0 1px 1px 0px;
  /* border-width: 0 0 1px 0px;*/
}
.forecast-wrapper .client-view table tbody th {
  font-weight: normal;
}
.forecast-wrapper .client-view table tbody th.sticky-header.facility {
  vertical-align: middle;
}
.forecast-wrapper .client-view table tbody th.sticky-header.facility a[href] {
  display: block;
  text-transform: none;
  padding-top: 4px;
  padding-bottom: 4px;
}
.forecast-wrapper .client-view table tbody td .form-control {
  width: 100%;
  text-align: left;
  background-color: #f7f6f9;
}
.forecast-wrapper .client-view table tbody td .form-control[readonly] {
  background-color: #fff;
  border: 0;
}
.forecast-wrapper .client-view table tbody td .form-control[disabled] {
  border: 1px solid transparent !important;
  background-color: transparent !important;
}
.forecast-wrapper .client-view table tbody td .form-control-error input {
  border: 1px solid #ff0000 !important;
}
.forecast-wrapper .client-view table tbody td .form-control-forecast-disabled {
  background-color: #f0f0f0 !important;
}
.forecast-wrapper .client-view table tbody td .form-control-forecast-disabled-read-only {
  background-color: transparent !important;
  border: 0px solid #000;
  color: #4a4157 !important;
}
.forecast-wrapper .client-view table tbody td .form-control-read-only {
  background-color: #c0c0c0 !important;
  border: 0px solid #000 !important;
  width: 90%;
  text-align: left;
  color: #000 !important;
}
.forecast-wrapper .client-view table tbody td .error-msg {
  color: #ff0000 !important;
  display: block;
}
.forecast-wrapper .cem-view .p-inputnumber .p-inputtext {
  font-size: 12px;
  height: 24px;
  padding-left: 4px !important;
}
.forecast-wrapper .cem-view table {
  font-size: 0.75rem;
  letter-spacing: 0.08em;
  border-collapse: separate !important;
  min-width: 100%;
  table-layout: fixed !important;
  border-spacing: 0;
  border-bottom: 0;
}
.forecast-wrapper .cem-view table .sticky-header {
  position: sticky;
  background: #fff;
  z-index: 3;
  width: 200px;
}
.forecast-wrapper .cem-view table .sticky-header.service {
  left: 0;
}
.forecast-wrapper .cem-view table .sticky-header.account {
  left: 200px;
  border-right: 1px solid #ccc;
  padding-top: 12px;
  background-color: #fff;
  width: 200px;
  z-index: 999;
}
.forecast-wrapper .cem-view table .sticky-header.facility {
  left: 400px;
  width: 200px;
  text-transform: uppercase;
}
.forecast-wrapper .cem-view table .sticky-header.volume {
  width: 1200px;
  position: unset;
}
.forecast-wrapper .cem-view table tr.cem-row td,
.forecast-wrapper .cem-view table tr.cem-row th.facility {
  border-bottom: 0px solid red !important;
}
.forecast-wrapper .cem-view table tbody th,
.forecast-wrapper .cem-view table tbody td {
  border: 1px solid #ccc;
  height: 36px !important;
  border-width: 0 0 1px 0px;
}
.forecast-wrapper .cem-view table tbody th {
  font-weight: normal;
}
.forecast-wrapper .cem-view table tbody th.sticky-header.facility {
  vertical-align: middle;
}
.forecast-wrapper .cem-view table tbody th.sticky-header.facility a[href] {
  display: block;
  text-transform: none;
  padding-top: 4px;
  padding-bottom: 4px;
}
.forecast-wrapper .cem-view table tbody td .form-control {
  width: 100%;
  text-align: left;
  background-color: #f7f6f9;
}
.forecast-wrapper .cem-view table tbody td .form-control[readonly] {
  background-color: #fff;
  border: 0;
}
.forecast-wrapper .cem-view table tbody td .form-control[disabled] {
  border: 1px solid transparent !important;
  background-color: transparent !important;
}
.forecast-wrapper .cem-view table tbody td .form-control-error input {
  border: 1px solid #ff0000 !important;
}
.forecast-wrapper .cem-view table tbody td .form-control-forecast-disabled {
  background-color: #f0f0f0 !important;
}
.forecast-wrapper .cem-view table tbody td .form-control-forecast-disabled-read-only {
  background-color: #c0c0c0 !important;
  border: 0px solid #000;
  color: #4a4157 !important;
}
.forecast-wrapper .cem-view table tbody td .form-control-read-only {
  background-color: #c0c0c0 !important;
  border: 0px solid #000 !important;
  width: 90%;
  text-align: left;
  color: #000 !important;
}
.forecast-wrapper .cem-view table tbody td .error-msg {
  color: #ff0000 !important;
  display: block;
}

.highlighted-rows,
.accuracy-or-actuals {
  background-color: #fcf4f9 !important;
}

.forecast-wrapper .client-view table tbody > :nth-child(2) .facility,
.forecast-wrapper .client-view table tbody > :nth-child(3) .facility,
.forecast-wrapper .client-view table tbody > :nth-child(4) .facility,
.forecast-wrapper .client-view table tbody > :nth-child(2) .account,
.forecast-wrapper .client-view table tbody > :nth-child(3) .account,
.forecast-wrapper .client-view table tbody > :nth-child(4) .account {
  background-color: #fcf4f9 !important;
}

.forecast-wrapper .client-view table tbody > :nth-child(1) th,
.forecast-wrapper .client-view table tbody > :nth-child(1) td,
.forecast-wrapper .client-view table tbody > :nth-child(1) .facility,
.forecast-wrapper .client-view table tbody > :nth-child(4) th,
.forecast-wrapper .client-view table tbody > :nth-child(4) td,
.forecast-wrapper .client-view table tbody > :nth-child(4) .facility {
  border-bottom: 1px solid #c0b0d6;
}

.forecast-wrapper .client-view table .border-bt-0 {
  border-bottom-width: 0px !important;
}

.forecast-wrapper .client-view table tbody td .form-control-forecast-disabled,
.forecast-wrapper .cem-view table tbody td .form-control-forecast-disabled {
  background-color: #fcf4f9 !important;
}

.forecast-wrapper .client-view table td.week-cols {
  border-left-width: 0px !important;
}

.forecast-wrapper .cem-view table tbody th.no-btm-bdr {
  border-bottom: 3px solid #fff !important;
}

.forecast-wrapper .cem-view .table thead th.no-top-bdr {
  border-top: 0px solid #fff !important;
}

.forecast-wrapper .cem-view table tbody > :nth-child(2),
.forecast-wrapper .cem-view table tbody > :nth-child(2) th,
.forecast-wrapper .cem-view table tbody > :nth-child(3) th,
.forecast-wrapper .cem-view table tbody > :nth-child(3) td,
.forecast-wrapper .cem-view table tbody > :nth-child(4),
.forecast-wrapper .cem-view table tbody > :nth-child(4) th,
.forecast-wrapper .cem-view table tbody > :nth-child(5),
.forecast-wrapper .cem-view table tbody > :nth-child(5) th,
.forecast-wrapper .cem-view table tbody > :nth-child(6),
.forecast-wrapper .cem-view table tbody > :nth-child(6) th,
.forecast-wrapper .cem-view table tbody > :nth-child(3) td .form-control[readonly],
.forecast-wrapper .cem-view table tbody > :nth-child(4) td .form-control[readonly],
.forecast-wrapper .cem-view table tbody > :nth-child(5) td .form-control[readonly],
.forecast-wrapper .cem-view table tbody > :nth-child(6) td .form-control[readonly] {
  background-color: #fcf4f9 !important;
}

.forecast-wrapper .cem-view table th.border-btm-zero {
  border-bottom-width: 0px !important;
}

.forecast-wrapper .client-view table tbody td .form-control-last .p-inputtext,
.forecast-wrapper .cem-view table tbody td .form-control-last .p-inputtext {
  width: 127px !important;
}

.forcast-difference-table {
  width: 70%;
  margin: auto;
}

.forcast-difference-table td {
  text-transform: uppercase;
}

.forecast-wrapper .client-view table tbody td .form-control[readonly] .float-right {
  float: right;
}

.volume-arriving-layer {
  position: sticky;
  left: 650px;
  width: 498px;
  z-index: 9;
}

.pos-relative {
  position: relative;
}

.text-align-right-mr {
  text-align: right;
  margin-right: 10px;
}

.forecast-checkbox-label {
  position: relative;
  top: 2px;
  left: 10px;
}

.font-weight-bold {
  font-weight: bold;
}

.align-parent-store-front {
  padding-top: 30px;
}

.hide {
  position: absolute !important;
  top: -9999px !important;
  left: -9999px !important;
  background-color: red;
}

th.align-from-top-first-child {
  padding-top: 50px;
}

th.align-from-top {
  padding-top: 16px;
}

.nested-table-velocity tr:first-child td {
  border-top: 0px solid red !important;
}

.nested-table-velocity tr:last-child td,
.nested-table-velocity table {
  border-bottom: 0px solid red !important;
}

.align-right {
  text-align: right;
}

.align-center {
  text-align: center;
}

.nested-table-velocity table td {
  height: 67px;
}

.forecast-graph-loading-template {
  min-height: 234px !important;
}

.secondary-header {
  font-family: "PrecisionSansW-Light", "Precision Sans W Light", "Precision Sans W", sans-serif;
  font-weight: 200;
  font-style: normal;
  font-size: 24px;
  margin-top: 10px;
  color: #b70077;
}

.internal-account-details {
  font-style: normal;
  font-size: 15px;
  color: #5f4f77;
}

.select-error-clients div.ng-select-container {
  border: 1px solid red !important;
}

.forecast-details-capitalize {
  text-transform: capitalize;
}

.shepherd-element {
  max-width: 350px;
}
.shepherd-element .shepherd-content .shepherd-text {
  text-align: left;
  padding: 1.5rem;
  color: var(--text);
}
.shepherd-element .shepherd-footer {
  position: unset;
  justify-content: left;
  padding: 0;
  border-top: 1px solid var(--gray200);
  background-color: var(--gray100);
  font-size: 1rem;
}

.page-home {
  --weather-grid-rows: 3 / 5;
}
.page-home .welcome {
  min-height: 200px;
  display: block;
  background-image: url(/assets/images/home/placeholder.png);
  background-position: center;
  background-size: cover;
}
.page-home .welcome-overlay {
  background-color: rgba(255, 255, 255, 0.175);
  border-radius: 4px;
  transition: all 0.2s ease-in-out;
  transform: scale(1);
}
.page-home .welcome-overlay:hover {
  background-color: rgba(255, 255, 255, 0.275);
  transform: scale(1.1);
}
.page-home .welcome-notification {
  position: relative;
}
.page-home .welcome-notification::after {
  content: "";
  display: inline-block;
  position: absolute;
  left: 5px;
  width: 10px;
  height: 10px;
  background-color: #fff;
  border-radius: 50%;
}
.page-home .card .card-body {
  padding-right: 1.25rem;
  padding-left: 1.25rem;
}
.page-home .card-bordered {
  background-color: transparent;
  border: 1px solid var(--rich400);
  box-shadow: none;
}
.page-home .boxpoll .divider {
  border-top: 2px solid rgb(202, 31, 132);
}
.page-home .boxpoll .stretched-link:hover::after {
  box-shadow: 0 0 15px rgba(96, 80, 119, 0.6);
}
.page-home .grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(auto-fill, 1fr);
  grid-auto-rows: auto;
  gap: 30px;
}
@media (max-width: 991.98px) {
  .page-home .grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
.page-home .widget {
  display: grid;
}
.page-home .weather {
  grid-column: 1/3;
  grid-row: var(--weather-grid-rows);
}

.page-billing-faq .section-head:hover {
  text-decoration: none;
}
.page-billing-faq .card:first-of-type {
  border-top: none;
}
.page-billing-faq .card .card-header .card-ripple-header {
  padding: 1rem 2rem 1rem 1rem;
}
.page-billing-faq .card .card-body {
  border-top: 1px solid var(--divider_color);
}
.page-billing-faq .fixedSidenav {
  position: fixed;
  z-index: 1;
  overflow-x: hidden;
}
.page-billing-faq .fixedSidenav .list-group a {
  color: var(--text);
}
.page-billing-faq .accordion .card-header button[aria-expanded=true],
.page-billing-faq ngb-accordion .card-header button[aria-expanded=true] {
  color: var(--hyperlink);
}
.page-billing-faq .textUpperCase {
  text-transform: uppercase;
}
.page-billing-faq #expdeliver-Transaction-Detail-Glossary-panel .table-open > tbody > tr > td:first-child,
.page-billing-faq #cbr-Transaction-Detail-Glossary-panel .table-open > tbody > tr > td:first-child,
.page-billing-faq #cbds-Transaction-Detail-Glossary-panel .table-open > tbody > tr > td:first-child {
  text-transform: uppercase;
}

.page-case-details #case-detail #caseDetailsLeftSection .textCollapsed {
  max-height: 5rem;
  text-overflow: ellipsis;
  overflow: hidden;
}
.page-case-details #case-detail #caseDetailsLeftSection .lineclamp {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.page-case-details #case-detail #caseDetailsLeftSection .description {
  display: -webkit-box;
  -webkit-line-clamp: 8;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.page-case-details #case-detail #caseDetailsLeftSection .comment-avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--text);
  color: var(--text);
  width: 32px;
  height: 32px;
  border-radius: 50%;
  font-size: 1.09em;
  text-transform: uppercase;
  padding: 2px 0 0 0;
}
.page-case-details #case-detail #caseDetailsLeftSection div.border-last:last-child {
  border-bottom: 1px solid silver;
}
.page-case-details #case-detail #caseDetailsLeftSection .small + p.word-break {
  margin-top: -1.3rem;
  word-break: break-all;
}
.page-case-details #case-detail #caseDetailsLeftSection .comment-box-with-attachment {
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
  transition-property: border-color, box-shadow !important;
  border: 1px solid var(--divider_color);
  background-color: var(--rich100);
}
.page-case-details #case-detail #caseDetailsLeftSection .comment-box-with-attachment.active {
  box-shadow: 1px 1px 2px 3px var(--rich200);
}
.page-case-details #case-detail #caseDetailsLeftSection .comment-box-with-attachment textarea {
  border: 0;
  resize: none;
  min-height: 38px;
  height: 38px;
  transition: height 0.3s;
}
.page-case-details #case-detail #caseDetailsLeftSection .comment-box-with-attachment textarea:focus {
  border: 0 !important;
}
.page-case-details #case-detail #caseDetailsLeftSection .comment-box-with-attachment textarea.highlight {
  height: 84px;
  resize: vertical;
}
.page-case-details #case-detail #caseDetailsLeftSection .comment-box-with-attachment textarea.highlight-no-anim {
  transition: height 0s;
}
.page-case-details #case-detail #caseDetailsLeftSection .comment-box-with-attachment .mat-checkbox-layout {
  margin-bottom: 0;
}
.page-case-details #case-detail #caseDetailsLeftSection #caseDetailDescription .badge {
  letter-spacing: 0.2px;
  cursor: not-allowed;
}
.page-case-details #case-detail #caseDetailsLeftSection #commentBoxWithAttachment ::-webkit-input-placeholder,
.page-case-details #case-detail #caseDetailsLeftSection #pbInternalcommentBox ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: var(--text);
  opacity: 0.3;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: normal;
}
.page-case-details #case-detail #caseDetailsLeftSection #commentBoxWithAttachment ::-moz-placeholder,
.page-case-details #case-detail #caseDetailsLeftSection #pbInternalcommentBox ::-moz-placeholder {
  /* Firefox 19+ */
  color: var(--text);
  opacity: 0.3;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: normal;
}
.page-case-details #case-detail #caseDetailsLeftSection #commentBoxWithAttachment :-ms-input-placeholder,
.page-case-details #case-detail #caseDetailsLeftSection #pbInternalcommentBox :-ms-input-placeholder {
  /* IE 10+ */
  color: var(--text);
  opacity: 0.3;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: normal;
}
.page-case-details #case-detail #caseDetailsLeftSection #commentBoxWithAttachment :-moz-placeholder,
.page-case-details #case-detail #caseDetailsLeftSection #pbInternalcommentBox :-moz-placeholder {
  /* Firefox 18- */
  color: var(--text);
  opacity: 0.3;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: normal;
}
.page-case-details #case-detail #caseDetailsRightSection .table-unstyled > thead > tr > td:first-child {
  padding-left: 0;
}
.page-case-details #case-detail #caseDetailsRightSection .table-unstyled > thead > tr > td:nth-child(2) > a {
  word-break: break-all;
}
.page-case-details #case-detail #caseDetailsRightSection .col-5 {
  max-width: 46.66667%;
}
.page-case-details #case-detail #caseDetailsRightSection #caseDetailAttachment table {
  table-layout: auto;
}
.page-case-details #case-detail #caseDetailsRightSection #caseDetailAttachment table.rowCollapsed {
  max-height: 9rem;
  overflow: hidden;
}
.page-case-details #case-detail #caseDetailsRightSection #case-detail-sidebar.table {
  table-layout: fixed;
  width: 100%;
}
.page-case-details #case-detail #caseDetailsRightSection .word-break {
  word-break: break-all;
}
.page-case-details #case-detail #caseDetailsRightSection .attachmentListCollapsed {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.page-case-details #case-detail #caseDetailsRightSection .inline-flex {
  display: inline-flex;
}
.page-case-details #case-detail #caseDetailsRightSection .trasparent {
  background-color: transparent;
}
.page-case-details #textareaRef:focus + button {
  display: none;
}

.page-case-create {
  margin-bottom: 0;
}
.page-case-create .services .service {
  border: 1px solid var(--text);
  position: relative;
  cursor: pointer;
  background-color: #fff;
}
.page-case-create .services .service.active {
  background-color: var(--rich200);
}
.page-case-create .services .service.active::after {
  content: "";
  width: 17px;
  height: 13px;
  background-repeat: no-repeat;
  background-image: url("/assets/images/commonIcon/tip.svg");
  position: absolute;
  left: calc(50% - 6px);
  bottom: -12px;
}
.page-case-create .services .service img {
  filter: invert(31%) sepia(8%) saturate(2040%) hue-rotate(223deg) brightness(100%) contrast(87%);
}
.page-case-create .list-case-types {
  min-width: 250px;
}
.page-case-create .list-case-types li {
  display: block;
  margin-bottom: 0;
}
.page-case-create .list-case-types li .case-type {
  border-top: none;
  border-right: 4px solid transparent;
  border-bottom: none;
  border-left: none;
  border-radius: 0px;
  display: block;
  padding: 0.75rem;
  color: var(--text);
  text-decoration: none;
  margin-top: 0;
  margin-bottom: 0;
  background-color: var(--rich100);
}
.page-case-create .list-case-types li .case-type:hover {
  background-color: var(--rich200);
  cursor: pointer;
}
.page-case-create .list-case-types li .case-type.active {
  border-right-color: var(--text);
  background-color: var(--rich200);
  outline: none;
}
.page-case-create .list-case-types li .case-type:focus {
  outline-width: 1px;
  outline-color: var(--text);
  outline-offset: -1px;
  border-radius: 0px;
  outline: none;
}
.page-case-create .list-case-types li .case-type i[class^=pbi-icon] {
  font-size: 1.25rem;
  vertical-align: sub;
  padding-right: 0.5rem;
}
.page-case-create .list-case-reasons {
  margin-bottom: 0;
  border-left: 1px solid var(--divider_color);
  min-height: 250px;
  border-left: none;
  border-top: 1px solid var(--divider_color);
}
.page-case-create .list-case-reasons li {
  display: block;
  margin-bottom: 0.5rem;
}
.page-case-create .list-case-reasons li .case-reason {
  display: block;
  border: 1px solid var(--text);
  padding: 0.5rem;
  background-color: var(--white);
  color: var(--text);
  text-decoration: none;
  margin-bottom: 0;
  border-radius: 2px;
}
.page-case-create .list-case-reasons li .case-reason:hover {
  background-color: var(--rich200);
  cursor: pointer;
}
.page-case-create .list-case-reasons li .case-reason.active {
  color: #fff;
  background-color: var(--text);
  cursor: default;
}
.page-case-create .list-case-reasons li .case-reason:focus {
  outline-width: 1px;
  outline-color: var(--text);
  outline-offset: 0px;
  border-radius: 0px;
}
.page-case-create .case-reason-label::before {
  content: "";
  display: block;
  height: 100px;
  margin-top: -70px;
}
.page-case-create .one-container-tag {
  margin-top: 3px !important;
  font-size: 0.8rem !important;
}
.page-case-create .optional-value {
  font-size: 10px;
  font-style: italic;
  padding-right: 5px;
}
.page-case-create .brand-font-light {
  font-family: "PrecisionSans_W_Lt", "Helvetica Neue", Arial, sans-serif;
}
.page-case-create .faq-content {
  top: 100px;
}

.trackingDelivered div.card {
  border-width: 0px !important;
}

.trackingDelivered .accordion .card-header button[aria-expanded=true]::before, .trackingDelivered .accordion .card-header .btn.btn-link[aria-expanded=true]::before, .trackingDelivered .accordion-group .card-header button[aria-expanded=true]::before, .trackingDelivered .accordion-group .card-header .btn.btn-link[aria-expanded=true]::before, .trackingDelivered ngb-accordion .card-header button[aria-expanded=true]::before, .trackingDelivered ngb-accordion .card-header .btn.btn-link[aria-expanded=true]::before {
  transform: rotate(90deg) !important;
}

.trackingDelivered .accordion .card-header button::before, .trackingDelivered .accordion .card-header .btn.btn-link::before, .trackingDelivered .accordion-group .card-header button::before, .trackingDelivered .accordion-group .card-header .btn.btn-link::before, .trackingDelivered ngb-accordion .card-header button::before, .trackingDelivered ngb-accordion .card-header .btn.btn-link::before {
  display: inline-block !important;
  margin-top: 0 !important;
  margin-right: 0.3rem !important;
  content: "\eb47" !important;
  font-family: "pbi-icon-outline" !important;
  float: left !important;
  transform: rotate(0deg) !important;
  transition: transform 200ms !important;
}

.trackingDelivered .accordion .card-header button::after,  .trackingDelivered .accordion .card-header .btn.btn-link::after, .trackingDelivered .accordion-group .card-header button::after, .trackingDelivered .accordion-group .card-header .btn.btn-link::after, .trackingDelivered ngb-accordion .card-header button::after, .trackingDelivered ngb-accordion .card-header .btn.btn-link::after  {
  display: none !important;
}

.trackingDelivered .accordion .card-header button::after, .trackingDelivered .accordion .card-header .btn.btn-link::after, .trackingDelivered .accordion-group .card-header button::after, .trackingDelivered .accordion-group .card-header .btn.btn-link::after, .trackingDelivered ngb-accordion .card-header button::after, .trackingDelivered ngb-accordion .card-header .btn.btn-link::after {
  display: none !important;
}

.trackingDelivered .tracking-faq {
  text-align: justify;
  padding-left: 20px;
}

#userListSetting .dropdown-toggle:empty::after {
  display: none;
}

#userListPage .p-datatable-auto-layout > .p-datatable-wrapper {
  min-height: 315px;
}

.page-analytics-cycle-time .in-range {
  background-color: var(--rich200);
}
.page-analytics-cycle-time .border .btn-secondary.btn {
  border-bottom: none;
  border-top: none;
}

#rowexpandid .table-details-content {
  margin-left: 5em;
}

#rowexpandid {
  background: none;
  border-top: 1px solid var(--border_color);
}

.toolbar-right1 {
  padding-right: 20px;
}

.pbds-chart.pbds-chart-stacked-bar .bar-group .bar[height="0"],
.pbds-chart.pbds-chart-bar .bar[height="0"] {
  height: 0.01px;
}

.pbds-daterange-popover .input-group-append button {
  border: 1px solid #605077 !important;
}

.pr-6rem {
  padding-right: 6rem !important;
}

#payment-activity .p-datatable table .p-datatable table {
  border-bottom: none !important;
}

.mat-radio-button .mat-radio-ripple {
  display: none;
}

.pbds-daterange-popover .input-group-append .btn.btn-secondary {
  padding-top: 4px;
}

.page-case-create .list-case-reasons {
  border-top-width: 0px;
}

.mat-ripple-element {
  height: 10px;
}

.btn-group.border {
  border: 0.05px solid var(--btn_primary) !important;
  z-index: 1;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.toolbar .btn-group {
  height: 1.8rem;
}

.toolbar .btn-group label.btn-secondary,
.toolbar .btn-group label.btn {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

#statusFilter .mat-checkbox {
  display: block;
}

#merchantDataReturns .pbds-chart .axis .tick text,
#networkDataReturns .pbds-chart .axis .tick text {
  font-size: 12.5px;
}

#packageActivityReturnTable table > tbody > tr:hover {
  background: var(--rich200);
}

#deliveredPackages #navigationSection .tab-content .tab-pane {
  padding: 0;
}

#deliveredPackages .metric-block-indicator.increase,
#deliveredPackages .metric-block-indicator.decrease {
  color: #717171;
}

.mat-radio-outer-circle,
.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  left: 0px !important;
}

.mat-radio-label-content {
  padding-left: 10px !important;
}

.pb-icon-dochub {
  height: 20px !important;
  width: 20px !important;
}

.pb-icon-new-file {
  font-size: 9px;
  position: initial;
  left: 2px;
  color: #b70077;
}

.fontMedium {
  font-size: 0.9rem;
}

.tab-content .tab-pane {
  border-bottom: 0;
}

.alert-danger,
#toast-container .toast-error {
  background-image: url("data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjI2IiB2aWV3Qm94PSIwIDAgMjYgMjYiIHdpZHRoPSIyNiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIHRyYW5zZm9ybT0ibWF0cml4KC0xIDAgMCAtMSAyNSAyNSkiPjxjaXJjbGUgY3g9IjEyIiBjeT0iMTIiIHI9IjEyIiBzdHJva2U9IiNERTBGMEUiIHN0cm9rZS13aWR0aD0iMiIvPjxnIHRyYW5zZm9ybT0idHJhbnNsYXRlKDExIDUpIj48Y2lyY2xlIGN4PSIxIiBjeT0iMSIgZmlsbD0iI0RFMEYwRSIgcj0iMSIvPjxwYXRoIGQ9Im0xIDV2OCIgc3Ryb2tlPSIjREUwRjBFIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS13aWR0aD0iMiIvPjwvZz48L2c+PC9zdmc+") !important;
}

app-no-file-available-modal .p-datatable-wrapper table.p-datatable-table {
  border-bottom: none;
}

#caseDetailComment .card-filled-header .card-header {
  background-color: var(--rich100);
}

#cbdscycleTime .card-header + .card-body {
  padding-bottom: 50px;
}

.p-multiselect {
  background-color: transparent;
  border: 0px solid var(--border_color);
  height: 30px;
  min-width: 12rem;
  width: 12rem;
  max-width: 12rem;
}

.p-multiselect .p-multiselect-label {
  padding: 0.2rem 5px 0.2rem 0.25rem;
}

.dashboard .toolbar .form-group .report-segment.dropdown .btn.dropdown-toggle {
  background-image: none !important;
}

.report-segment button.dropdown-toggle.dropdown-toggle::after {
  display: none !important;
}

.pbi-icon-mini.pbi-small-triangle-down::before {
  font-size: larger;
}

.p-multiselect .p-multiselect-trigger,
.p-multiselect .p-dropdown-trigger,
.p-dropdown .p-multiselect-trigger,
.p-dropdown .p-dropdown-trigger {
  padding-right: 5px;
}

.dashboard .toolbar .form-group .dropdown .dropdown-menu {
  min-width: 16.3rem;
}

.p-datatable th,
.p-datatable td,
.p-treetable th,
.p-treetable td {
  padding: 0.35rem;
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

#caseFilter label {
  background: var(--rich200);
  font-size: 0.85rem;
  border: 1px solid transparent; /* keeps layout from jumping */
}

#caseFilter input {
  position: absolute;
  opacity: 0;
}

#caseFilter input[type=radio]:checked + label {
  background: var(--rich500);
  color: white;
}

#caseFilter input[type=radio]:checked + label.second {
  border-radius: 0px 4px 4px 0px;
}

#caseFilter input[type=radio]:checked + label.first {
  border-radius: 4px 0px 0px 4px;
}

#caseFilter .btn-group.border {
  border: 1px solid var(--btn_primary) !important;
}