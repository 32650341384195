#edr .min-width {
  min-width: 200px;
}

#rvpCalendar {
  .ngb-dp-navigation-select {
    display: flex;
    flex: 1 1 11rem !important;
  }
}
// prediction chart
.pbds-chart {
  &.pbds-chart-bar-grouped {
    .bar-group-prediction {
      .prediction-divider {
        stroke: var(--gray300);
        stroke-width: 1;
        stroke-dasharray: 10 10;
      }

      .bar {
        cursor: pointer;
      }

      .min-line {
        &.inactive {
          opacity: 0.1;
        }
      }

      .max-line {
        &.inactive {
          opacity: 0.1;
        }
      }

      .range-line {
        &.inactive {
          opacity: 0.1;
        }
      }
    }
  }
}

.returns-cycle-section-header {
  padding-bottom: 5px;
  padding-left: 0;
  margin: 0.5rem 1rem;
  border-bottom: 1px solid var(--border_color);
  color: var(--text);
  font-family: 'PrecisionSans_W_Rg', 'Helvetica Neue', Arial, sans-serif;
  text-transform: uppercase;
  font-size: 0.75rem;
}

.p-datatable th.edrExpand,
.p-datatable td.edrExpand {
  padding: 0.4rem !important;
  border-top-width: 0.4px !important;
}

.p-datatable th.edrExpand {
  border-bottom-width: 0.4px !important;
  border-top-width: 0.4px !important;
}

.p-datatable table {
  table-layout: auto !important;
}

.p-multiselect {
  min-width: 15rem;
}

.multiselect-custom-virtual-scroll .p-multiselect {
  min-width: 20rem;
}

.multiselect-custom {
  .p-multiselect-label {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }

  .p-multiselect-label-empty {
    visibility: visible;
  }

  .status-item-value {
    border-radius: 3px;
    display: flex !important;
    margin-right: 0.5rem;
    background-color: var(--primary-color);
    color: var(--primary-color-text);

    img.flag {
      width: 20px;
    }
  }

  .p-multiselect-item {
    display: flex !important;
  }
}

.pbds-chart {
  &.pbds-chart-choropleth-map {
    .map {
      .feature {
        fill: var(--rich300);
      }
    }
  }
}
