.list-group-item {
  border-top: 0px;
  border-bottom: 0px;
}

.filter-width {
  max-width: 250px;
}

.pull-right {
  float: right;
}
