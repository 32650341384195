.create-receive-items {
  .fulfill-section {
    margin-top: 2rem;
    border-top: 1px solid var(--divider_color);
  }

  .input-icons i {
    position: absolute;
  }

  .search-icon-color {
    color: #b3b6c2;
  }

  .icon {
    padding: 12px;
    min-width: 40px;
  }

  .input-field {
    width: 100%;
    padding-left: 40px;
    text-align: left;
  }
}
