.page-analytics-cycle-time {
  .in-range {
    background-color: var(--rich200);
  }
  .border .btn-secondary.btn {
    border-bottom: none;
    border-top: none;
  }
}

#rowexpandid .table-details-content {
  margin-left: 5em;
}

#rowexpandid {
  background: none;
  border-top: 1px solid var(--border_color);
}
